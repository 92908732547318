import React from 'react'

const Box1 = () => {
  return (
    <div>
      <div className="bg-danger heading d-flex justify-content-center align-items-center">
        <h1 className="text-white">Interest Rates</h1>
      </div>

      <div className='w-75 mx-auto pt-5 my-5'>
        <h1 className='pt-5'>What are the Current Used Car Loan Interest Rates?</h1>
        <p className='text-muted py-5'>
          The used car loan interest rates are dependent on various factors
          including cost of funds, customer credentials, geography, asset,
          tenor, and discounts offered in the market. Besides that, your credit
          profile, monthly income, and repayment capacity also play a pivotal
          role. CLS ensures that you get a transparent and good deal at the time
          of availing of low-interest-rate used car loans. Our second-hand car
          loan interest rates are competitive in the market, starting at just
          15%.
        </p>
      </div>
    </div>
  );
}

export default Box1